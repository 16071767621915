@import url('https://fonts.googleapis.com/css2?family=Salsa&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

:root {
  --tw-bg-opacity: 0.5; /* Adjust the opacity as needed */
}



*{
  font-family: 'YourCustomFont' !;
  font-size: 20px !important;
}

body {
 background-color: #ffffff !important;
}
@font-face {
  font-family: 'YourCustomFont';
  src: url('./Fonts/Hand\ Marker.otf') format('opentype');
  /* You can also specify font-weight, font-style, etc. */
}

.bg{
  
}

.backoftop{
  background-color: #a9702a;
  padding: 10px;
}
.colorxbatcat{
  color: #ffffff;
}

.backofdesk{
  background-color: #00c51e72;
  height: 100%;
}
.backofdesks{
  background-color: #222831;
  height: 100%;
}

.centeritall{
  justify-content: center;
  align-items: center;
  display: flex;
}

.topimg{
  width: 74%;
}
.topimgm{
  width: 100% !important;
}

.maintext{
  text-align: center;
  font-family: 'YourCustomFont';
  color: #000000;
  font-size: 80px !important;
}

.maintextos{
  text-align: start;
  color: #000000;
  font-weight: 900px;
  font-family: 'YourCustomFont';
  text-align: center;
  font-size: 50px !important;
}

.maintextost{
  text-align: start;
  color: #000000;
text-align: center;
  font-size: 60px !important;
}
.maintextm{
  text-align: center;
  color: #ffffff;
  font-size: 60px !important;
}

.centerDCa{
  text-align: center;
  color: #000000;
  position: relative;
  top: 20px;
}

.buttonBuy{
  position: relative;
  top: 20px;
  right: 20px;
  align-items: end;
  text-align: end;
  display: ruby;
}

.socials{
  color: #ffffff;
  font-size: 45px !important;
}
.socialsm{
  color: #ffffff;
  font-size: 35px !important;
}

.coloroffooter{
  background-color: #ffffff;
}

.centercaf{
  color: #000000;
  text-align: center;
  font-size: 12px !important;
}

.bizlogo{
  font-size: 70px !important;
  color: #ffffff;
  position: relative;
  left: 20px;
}


.maintextoss{
  text-align: start;
  color: #000000;
  font-weight: 900px;
  text-align: center;
  font-size: 70px !important;
}

.maintextosts{
  text-align: start;
  color: #000000;
text-align: center;
  font-size: 30px !important;
}



.bg {


  background-repeat: no-repeat !important;
  background-size: cover !important;
    background: #00000091 url('./mdv.png') ;
    background-repeat: no-repeat!important;
    background-size: cover!important;
    background-position: center !important;
    bottom: 0;
    display: block;
    height: 100%;
    left: 0;
    overflow-x: hidden;
    position: absolute;
    width: 100%;
    z-index: 1111;
  }
  .pageCon {
   
  
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    bottom: 0;
    left: 0;
    overflow: hidden;
    right: 0;
    top: 0;
  }

























  .btnf {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    color: white;
    font-size: 18px;
    background-color: rgba(13, 13, 13, 0.3);
    padding: 0.4em 1.2em;
    border-style: dashed;
    border-radius: 0.25em;
    border-width: 2px;
    border-color: rgba(13, 13, 13, 0.75);
    transition: 0.2s ease;
  }
  
  .btnf:hover {
    background-color: rgb(255, 255, 255);
    color: #0d0d0d;
    border-color: #0d0d0d;
  }















  

  .btns {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    color: white;
    font-size: 14px;
    background-color: rgba(13, 13, 13, 0.3);
    padding: 0.4em 1.2em;
    border-style: dashed;
    border-radius: 0.25em;
    border-width: 2px;
    border-color: rgba(13, 13, 13, 0.75);
    transition: 0.2s ease;
  }
  
  .btns:hover {
    background-color: rgb(255, 255, 255);
    color: #0d0d0d;
    border-color: #0d0d0d;
  }


  .backofabou{
    background-color: #000000;
  }


  .a1{ font-family: 'YourCustomFont';
    font-size: 90px !important;
    text-align: center;
    
  }

  
  .a1p{
    font-family: 'YourCustomFont';
    font-size: 30px !important;
    color: #ffffff;
    text-align: center;
  }

  .cams{
    font-size: 12px !important;
  }